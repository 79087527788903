$(document).ready(function () {
    $("#document").mask("999.999.999-99");
    $("#nrdocument").mask("99.999.999/9999-99");
    // $("#dtnascimento").mask("99/99/9999");
    $("#phone").mask("(99) 99999-9999");
    $(".date").mask("99/99/9999");
    $(".month").mask("99/9999");
    $(".year").mask("9999");
    $('.money').mask("#.##0,00", {reverse: true});

    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });

    $('.js-select-form').select2();


    $('#myTableItens').DataTable({
        pagingType: 'full_numbers',
        columnDefs: [
            { width: '50%', targets: 1 },
            { width: '5%', targets: 0 }
        ],
        language: {
            url: '//cdn.datatables.net/plug-ins/1.13.6/i18n/pt-BR.json',
        }
    });

});


openCity = function(evt, cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent__comunnity");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
}


illnessFc = function(i)
{
    if(i == 1)
    {
        $("#dc").show(500);
        $("#rcu").hide("slow");
    }else{
        $("#rcu").show(500);
        $("#dc").hide("slow");
    }
}


